<template>
  <div class="container">
    <p class="mt-4 mb-3 text-left">
      東京医科歯科大学歯学部長&emsp;殿<br />
      研究責任者&emsp;大学院医歯学総合研究科&emsp;青木和広&emsp;殿
    </p>
    <h3 class="mt-4 mb-3" style="letter-spacing: 1.5rem">同意書</h3>
    <p class="mt-4 mb-3 text-left">
      &emsp;私は「唾液中の幸福度パラメータの特定および口腔内細菌叢と癌リスクとの関連解析」（承認番号：第D2021-083番）について、Web上の説明動画を視聴して説明を受け、その方法、危険性、研究結果の取り扱い等について以下の各項目を理解し、自らの自由意思で研究協力に同意します。なお、説明動画を補足する同意説明書はWeb上に掲載しています。また、以下のリンクからいつでも閲覧できます。
      <ul class="nav mt-3">
        <li><button type="button" class="btn_next btn btn-primary" @click="moveLink('/consent_manual.pdf')">同意説明書閲覧</button></li>
      </ul>
    </p>
    <p class="mt-4 mb-3 text-left">（以下、同意する項目にチェック（レ印）をつけてください。）</p>
    <div class="ipcheck mt-4">
      <form ref="form1" @submit.prevent>
        <div class="checkbox">
          <label for="check-1"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-1" @change="onChangeCheck" required />本研究が倫理審査委員会の承認及び研究機関の長の許可を受けていることを理解しました。 </label>
        </div>
        <div class="checkbox">
          <label for="check-2"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-2" @change="onChangeCheck" required />研究の概要について説明書と動画を視聴し、理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-3"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-3" @change="onChangeCheck" required />研究の意義・目的について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-4"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-4" @change="onChangeCheck" required />研究の方法について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-5"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-5" @change="onChangeCheck" required />試料・情報等の保管・廃棄について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-6"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-6" @change="onChangeCheck" required />試料・情報の他の研究への二次利用について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-7"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-7" @change="onChangeCheck" required />予想される結果（利益・不利益）について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-8"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-8" @change="onChangeCheck" required />研究協力は自由意志に基づく任意で行い、撤回の自由があることを理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-9"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-9" @change="onChangeCheck" required />個人情報の保護・取り扱いについて理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-10"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-10" @change="onChangeCheck" required />研究に関する情報公開について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-11"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-11" @change="onChangeCheck" required />研究によって得られた結果のお知らせについて理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-12"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-12" @change="onChangeCheck" required />経済的な負担および謝礼について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-13"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-13" @change="onChangeCheck" required />研究資金および利益相反について理解しました。</label>
        </div>
        <div class="checkbox">
          <label for="check-14"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-14" @change="onChangeCheck" required />研究に係る相談・問い合わせ等の連絡先を理解しました。</label>
        </div>
        <div class="row mt-5">
          <h5 class="col-12 col-sm-8 px-0 mx-auto text-sm-center font-weight-bold">同意日：{{ year }} 年 {{ month }} 月 {{ day }} 日</h5>
        </div>
        <div class="mt-4 row text-left" style="margin-left: -30px; margin-right: -30px">
          同意書にすべてチェックをして頂いたうえで、次ページ以降のアンケートと応募フォームの入力完了後に同意書控えを電子メールでお送りします。
        </div>
        <div class="checkbox mt-4">
          <label for="check-15"><input type="checkbox" class="option-input02 option-input02-left2 checkbox" id="check-15" @change="onChangeCheck" required /> 同意書控えの出力方法を確認しました。</label>
        </div>
        <ul class="nav mt-3">
          <li><button type="button" class="btn_next btn btn-primary" @click="onClickConsentButton">同意する</button></li>
        </ul>
        <ul class="nav mt-3">
          <li><button type="button" class="btn_next btn btn-secondary" @click="back">戻る</button></li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
import { Mixin } from '@/mixin.js'
import { checkStatus } from '@/utils/common.js'

export default {
  mixins: [Mixin],
  data() {
    const now = new Date()
    return {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate(),
    }
  },
  async created() {
    // ステータスチェック
    checkStatus(this, 3)
  },
  async mounted() {
    if (localStorage.getItem('consentFlag') == 1) {
      Array.from(document.querySelectorAll('input[type=checkbox]'), elem => {
        elem.checked = true
      })
    }
  },
  methods: {
    onClickConsentButton() {
      if (!this.$refs.form1.reportValidity()) {
        alert('項目をすべてチェック頂くことで事前アンケートへ進めます。')
        return
      }

      // localStorageに同意フラグを保存
      localStorage.setItem('consentFlag', '1')

      // 事前アンケート画面へ
      this.$router.push({name: 'AdvanceSurvey'})
    },
    onChangeCheck() {
      if (localStorage.getItem('consentFlag') == 1) {
        Array.from(document.querySelectorAll('input[type=checkbox]'), elem => {
          if (!elem.checked) {
            localStorage.removeItem('consentFlag')
          }
        })
      }
    },
    moveLink(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style src="../assets/css/pages/questionnaire.css" scoped></style>
